import React from 'react';
import {CSSTransition} from 'react-transition-group';
import classNames from "classnames";
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import resolvePath from './resolvePath';
import templateApplyTags from './templateApplyTags';

const Modal = ({ post, template, transitions, baseurl, urlParams, onClosed, onLinkClick, prevNext, onPrevNextClick, noHistory }) => {

  const permalink = baseurl + post?.slug + '/' + urlParams;

  const [data, setData] = React.useState(null);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (!post) {
      close();
      return;
    }
    setShow(true);
    reportOpen();
    if (!noHistory) {
      window.history.pushState(null, null, permalink);
      window.addEventListener('popstate', close);      
    }
    setData(post);
  }, [post]); // eslint-disable-line react-hooks/exhaustive-deps

  const close = (e) => {
    if (e) e.preventDefault();
    setShow(false);
    if (!noHistory) window.removeEventListener('popstate', close);  
  }

  const reportOpen = () => {
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('ws_cmp_filtered_list__modal_open'));  
    }, 200);
  }

  const reportClose = () => {
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('ws_cmp_filtered_list__modal_close'));  
    }, 100);
  }

  const handleExited = () => {
    if (!noHistory) window.history.pushState(null, null, baseurl);
    onClosed();
  }

  const handleClick = () => {
    setShow(false);
    reportClose();
  }

  const nodeRef = React.useRef(null);
  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={show}
      classNames="wscfl-modal-"
      unmountOnExit
      onExited={handleExited}
      {...transitions.modal}
    >
      <div
        ref={nodeRef}
        className={classNames("wscfl-modal", {'wscfl-modal--is-loading': data === null})}
        onClick={handleClick}
      >
        <div
          className="wscfl-modal__content"
          onClick={(e) => {e.stopPropagation()}}
        >
          {parse(fillOutTemplate(data, template, permalink), {
            // replace links which have class "internal" with internal links
            replace: ({ attribs, children }) => {
              if (attribs && attribs.class && attribs.class.includes('internal')) {
                const props = attributesToProps(attribs);
                return <TemplateLink
                  onClick={onLinkClick}
                  {...props}
                >{domToReact(children)}</TemplateLink>;
              }
            }
          })}
        </div>
        { onPrevNextClick && <i className="wscfl-modal__prev" onClick={(e) => {e.stopPropagation(); onPrevNextClick(-1)}}></i> }
        { onPrevNextClick && <i className="wscfl-modal__next" onClick={(e) => {e.stopPropagation(); onPrevNextClick(1)}}></i> }
        <i className="wscfl-modal__close"></i>
      </div>
    </CSSTransition>
  )
}

const TemplateLink = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    props.onClick(props['data-slug']);
  }
  return (
    <a
      href={props.href}
      onClick={handleClick}
    >
      {props.children}
    </a>
  )
}

const fillOutTemplate = (data, template, permalink) => {
  let html = templateApplyTags(data, template);
  const tagMatches = [...template.matchAll(/{{ (.+?) }}/g)];
  tagMatches.forEach((tagMatch) => {
    html = html.replace(
      tagMatch[0],
      getDataForTag(data, tagMatch[1], permalink)
    );
  });
  return html;
}

const getDataForTag = (data, tag, permalink) => {
  if (tag === 'permalink') return permalink;
  if (tag === 'uriencode(permalink,0)') return encodeURIComponent(permalink);
  const foundData = resolvePath(data, tag, '');
  if (!foundData.length) return '';
  return (typeof foundData.join ===  'function' ? foundData.map((term) => (term.name)).join(', ') : foundData);
}

export default Modal;