import dateFormat from 'dateformat';

const resolvePath = (object, path, defaultValue, permalink) => {

  path = path.replace(/{|}/g, ''); // remove braces if present

  // default parameters
  const params = {
    glue: ', ',
  };
  // extract parameters added and separated by «|»
  // example: mypath|key=value|key2=value2|etc.
  const splitParams = path.split('|');
  if (splitParams.length > 1) {
    for (var i = 1; i < splitParams.length; i++) {
      let param = splitParams[i].split('=');
      params[param[0]] = param[1];
    }
  };
  path = splitParams[0];

  var functionCheck = path.match(/(.+?)\((.+?),(.+?)\)/); // for now functions have to have 2 arguments
  if (functionCheck) {
    if (functionCheck.length !== 4) throw new Error('resolvePath: Faulty function patten');
    path = functionCheck[2];
  }

  let result = path
    .split('?').reduce((prev, cur) => (
      typeof prev !== 'undefined' && (prev || prev.length || prev === 0) ?
        prev :
        cur.split(':')[0].split('.').reduce((prev, cur) => 
          prev ?
            ( typeof prev[cur] === 'undefined' ?
              defaultValue : 
              prev[cur]
            ) :
            defaultValue
          , object
        )
    ), '');

  // : —> join array of objects
  if (typeof result === 'object' && path.split(':').length === 2) {
    let results = [];
    result.forEach((parent) => {
      results.push(parent[path.split(':')[1]]);
      if (
        typeof parent.children !== 'undefined' &&
        parent.children.length
      ) {
        results = results.concat(
          parent.children.map((child) => child[path.split(':')[1]]).join(params.glue)
        );
      } 
    });
    return results.join(params.glue);
  }

    

  // join array
  if (typeof result === 'object' && typeof result.join === 'function')
    return result.join(params.glue);

  // apply function
  if (functionCheck) {
    switch (functionCheck[1]) {

        case 'date':
          if (result) result = dateFormat(new Date(result), functionCheck[3])
          break;

        case 'if':
          result = result ? functionCheck[3] : '';
          break;

        case 'uriencode':
          result = encodeURIComponent(result);
          break;

        default:
          throw new Error('resolvePath: Unknown function type');

    }
  }

  return result;

}

export default resolvePath;