import React from 'react';
import classNames from "classnames";

const ResultsCount = ({numberOfPosts, labels}) => {
  return !!labels && (
    <div className={
      classNames("wscfl-results-count", {
        'wscfl-results-count--has-posts': numberOfPosts,
      })
    }>{numberOfPosts + ' ' + labels[numberOfPosts === 1 ? 'singular' : 'plural']}</div>
  )
}

export default ResultsCount;