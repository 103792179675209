import React from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import resolvePath from './resolvePath';
import parse from 'html-react-parser';

const List = (props) => {

  React.useEffect(() => {
    if (props.isDataLoaded) window.dispatchEvent(new CustomEvent('ws_cmp_filtered_list__length_changed'));
  }, [props.list.length, props.isDataLoaded]);

  if (props.type ===  'table') return (
    <table className="wscfl-list">
      <ListHead labels={props.labels}/>
      <ListBody {...props}/>
    </table>
  );
  return <ListBody {...props}/>

}

const ListHead = ({labels}) => {
  if (typeof labels === 'undefined' || typeof labels.length === 'undefined' || !labels.length) return null;
  return (
    <thead>
      <tr>
        {labels.map((label, idx) => ( <th key={'th'+idx}>{label}</th> ))}
      </tr>
    </thead>
  )
}

const ListBody = ({ type, list, templates, transitions, onModalRequest, isDataLoaded }) => {
  return (
    <TransitionGroup 
      className={type === 'table' ? null : 'wscfl-list'}
      component={type === 'table' ? 'tbody' : 'ul'}
      exit={false}
    >
    {list.map((item) => (
      <ItemAnimator key={item.id} type={type} item={item} templates={templates} transitions={transitions} onModalRequest={(slug)=>{onModalRequest(slug)}} />
    ))}
    </TransitionGroup>
  )
}

const ItemAnimator = ({type, item, transitions, templates, onModalRequest, ...rest}) => {

  const nodeRef = React.useRef(null);
  const ItemTag = `${type === 'table' ? 'tr'    : 'li'}`;
  const conditionalClasses = templates.conditional_classes ?  
    templates.conditional_classes
      .filter((conditionalClass) => applyCondition(item, conditionalClass))
      .map(   (conditionalClass) => conditionalClass.className)
      .join(' ')
    : false;
  const className = (conditionalClasses ? conditionalClasses + ' ' : '' ) +
    'wscfl-list__' + (item.isDivider ? 'divider' : 'item');

  const [isActive, setIsActive] = React.useState(false);
  const handleToggleActive = () => { setIsActive(!isActive) }

  return (
    <CSSTransition
      {...rest}
      nodeRef={nodeRef}
      timeout={transitions.item.timeout}
      classNames={className + '-'}
    >
      <ItemTag
        ref={nodeRef} 
        className={className + (isActive ? ' ' + className + '--is-active' : '')}
      >
        <Item
          item={item}
          template={templates[item.isDivider ? 'divider' : 'item']}
          pkey={item.id}
          onModalRequest={(slug)=>{onModalRequest(slug)}}
          onToggleActive={handleToggleActive}
        />
      </ItemTag>
    </CSSTransition>
   )
}

const Item = ({ item, template, onModalRequest, onToggleActive }) => (
  <>
    {template.map((elmTemplate, idx, pkey) => (
      <ItemElement
        key={pkey+'-'+idx}
        elmTemplate={elmTemplate}
        item={item}
        pkey={pkey+'-'+idx}
        onModalRequest={(slug)=>{onModalRequest(slug)}}
        onToggleActive={onToggleActive}
      />
    ))}
  </>
)

const ItemElement = ({ elmTemplate, item, pkey, onModalRequest, onToggleActive }) => {

  if (!applyCondition(item, elmTemplate)) return null;

  const Tag = `${elmTemplate.tag}`;
  const populatedAttributes = populateAttributes(Object.assign({},elmTemplate.attributes), item);

  const handleModalRequest = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onModalRequest(item.slug);
  }
  const handleLinkClick = (e) => {
    e.stopPropagation();
  }
  if (typeof elmTemplate.open_modal !== 'undefined' && elmTemplate.open_modal) {
    populatedAttributes.onClick = handleModalRequest;
  } else if (elmTemplate.toggle_active) {
    populatedAttributes.onClick = onToggleActive;
  } else if (elmTemplate.tag === 'a') {
    populatedAttributes.onClick = handleLinkClick;
  }

  if (typeof elmTemplate.content === 'undefined' || !elmTemplate.content) {
    return (
      <Tag {...populatedAttributes}/>
    )
  } else if (typeof elmTemplate.content === 'string') {
    const contentString = applyTemplateTag(elmTemplate.content, item);
    if (!contentString.length) return null;
    return (
      <Tag {...populatedAttributes}>{parse(contentString)}</Tag>
    )
  } else if (typeof elmTemplate.content === 'object') {
    return (
      <Tag {...populatedAttributes}>
        <Item item={item} template={elmTemplate.content} pkey={pkey} onModalRequest={onModalRequest} />
      </Tag>
    )
  }
  return null;
}

const populateAttributes = (attributes, data) => {
  for (var name in attributes) {
    if (name === 'style') {
      let style = {}
      for (var styleName in attributes[name]) {
        style[styleName] = applyTemplateTag(attributes[name][styleName], data);
      }
      attributes[name] = style;
    } else {
      attributes[name] = applyTemplateTag(attributes[name], data);      
    }
  }
  return attributes;
}

const applyTemplateTag = (string, data) => (
  string.match(/{(.+?)}/) ?
    string.replace(/{(.+?)}/g, (match) => { return (resolvePath(data, match, '') || '')}) :
    string
);

const applyCondition = (item, template) => {
  if (typeof template.condition === 'undefined') return true;
  const condition = resolvePath(item, template.condition);
  if (template.condition_compare === 'NOT') {
    if (
      typeof condition !== 'undefined' && typeof condition.length !== 'undefined' &&
      condition.length
    ) return false;
  } else if (
    typeof condition === 'undefined' || typeof condition.length === 'undefined' ||
    !condition.length
  ) return false;
  return true;
};

export default List;