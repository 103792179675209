import resolvePath from './resolvePath';

function preparePosts(posts, sortDefs, searchDefs, conditions, allowedTerms) {
  posts = applyConditions(posts, conditions);
  posts = applyAllowedTerms(posts, allowedTerms);
  posts = sortPosts(posts, sortDefs);
  posts = populateSearch(posts, searchDefs);
  posts = groupPosts(posts, sortDefs);
  return posts;
}

function applyConditions(posts, conditions) {
  if (!conditions) return posts;
  return posts.filter((post) => {
    var isIn = true;
    for (var i = 0; i < conditions.length; i++) {
      var result = resolvePath(post, conditions[i].path);
      isIn = isIn && result === conditions[i].value;
    }
    return isIn;
  });
}

const applyAllowedTerms = (posts, allowedTerms) => 
  !allowedTerms 
    ? posts 
    : posts.filter(post => 
        allowedTerms.every(allowedTerm => 
          post.terms[allowedTerm.slug].some(term => allowedTerm.terms.includes(term.slug))
        )
      );

function sortPosts(posts, sortDefs) {
  if (!sortDefs.length) return posts
  // skip posts that do not have all sort criteria defined
  posts = posts.filter((post) => sortDefs.every(dimension => (
    dimension.type === 'string' ? resolvePath(post, dimension.path, '') : !!post.terms[dimension.slug] && post.terms[dimension.slug].length
  )));
  // sort the posts
  for (var i = sortDefs.length - 1; i >= 0; i--) {
    const dimension = sortDefs[i];
    switch (dimension.type) {
      case 'string' :
        posts = posts.sort(function(a, b){
          return resolvePath(a, dimension.path, '').localeCompare(resolvePath(b, dimension.path, ''));
        });
        break;
      default : // defaults to 'taxonomy'
        posts = posts.sort(function(a, b){
          if (!a.terms[dimension.slug].length) return 1;
          if (!b.terms[dimension.slug].length) return -1;
          if ('term_order' in a.terms[dimension.slug][0] && 'term_order' in b.terms[dimension.slug][0])
            return a.terms[dimension.slug][0].term_order < b.terms[dimension.slug][0].term_order ? -1 : 1;
          return a.terms[dimension.slug][0].name.localeCompare(b.terms[dimension.slug][0].name);
        });
        break;
    }
  }
  return posts;
}

function populateSearch(posts, searchDefs) {
  return posts.map((post) => ({
    search: searchDefs.map((def) => {
      if (def.type === 'string') return {
        value: resolvePath(post, def.key, false) || '',
        ...def
      };
      if (def.type === 'taxonomy' && post.terms[def.key]) return {
        value: post.terms[def.key].map((term) => (
          (
            (
              'children' in term && term.children.length ? 
              term.children.map((term) => (term.name)) + ' ' :
              ''
            ) + 
            term.name
          )
        )).join(' '),
        ...def
      }
      return def;
    }),
    ...post
  }));
}

function groupPosts(posts, sortDefs) {
  if (!sortDefs.length || !sortDefs[0].group) return posts;
  let lastTerm = '';
  let newTerm = '';
  let groupIndex = 0;
  let groupedPosts = [];
  let groupTerms = {};
  let groupSearchValues = [];
  for (var i = 0; i < posts.length; i++) {
    if (!posts[i].terms[sortDefs[0].slug][0]) continue; // post does not have the group term, exclude post
    newTerm = posts[i].terms[sortDefs[0].slug][0].name;
    if (newTerm !== lastTerm) {
      // CONSTRUCT A DIVIDER
      groupTerms = {};
      groupSearchValues = [];
      // all posts in group…
      for (var j = 0; j < posts.length; j++) {
        //// Search
        let hasTerm = false;
        // is this post relevant?
        for (var termKey in posts[j].terms[sortDefs[0].slug]) {
          hasTerm = hasTerm || posts[j].terms[sortDefs[0].slug][termKey].name === newTerm;
        }
        if (!hasTerm) continue;
        // it is…
        // add post terms to groupTerms
        for (var termSlug in posts[j].terms) {
          if (typeof groupTerms[termSlug] === 'undefined') groupTerms[termSlug] = [];
          groupTerms[termSlug].push(...posts[j].terms[termSlug]);
        }
        // add post search to group
        for (var k = 0; k < posts[j].search.length; k++) {
          groupSearchValues.push(posts[j].search[k].value);
        }
      }
      // add the divider to list
      groupedPosts.push({
        id: 'divider-'+groupIndex++,
        isDivider: true,
        title: newTerm,
        terms: groupTerms,
        search: [...new Set(groupSearchValues)].filter( (value) => (value ? value.length : false) ).map( (value) => ({value: value}) )
      });
    }
    lastTerm = newTerm;
    groupedPosts.push(posts[i]);
  }
  return groupedPosts;
}

export default preparePosts;