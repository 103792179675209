import React from 'react';
import preparePosts from './preparePosts';
import getFilterOptions from './getFilterOptions';
import applyFilter from './applyFilter';
import applyPagination from './applyPagination';
import FilterPanel from './FilterPanel';
import FiltersList from './FiltersList';
import ResultsCount from './ResultsCount';
import Modal from './Modal';
import List from './List';
import ShowMore from './ShowMore';
import classNames from "classnames";

const args = JSON.parse(localStorage.getItem('ws-cmp-filtered-list--args'));
args.baseurl = args.baseurl.replace(/\/?$/, '/');

const App = () => {

  const [data, setData] = React.useState(false);

  const [filtersSelected, setFiltersSelected] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  const [pages, setPages] = React.useState(1);

  const [isIntersecting, setIsIntersecting] = React.useState(false);

  const pageFound = document.location.href.match(/\/page\/(\d*)/);
  const [pageNo, setPageNo] = React.useState(pageFound ? parseInt(pageFound[1]) : false);

  const posts = data.posts || [];

  const urlParams = window.location.href.split('?').length > 1 ? '?'+window.location.href.split('?')[1] : '';

  // init
  React.useEffect(() => {

    // look for initial slug
    const initalSlug = window.location.href.replace(/\/?$/, '/').replace(args.baseurl, '').replace(/\/?$/, '').split('?')[0].replace(/\/?$/, '');

    // get data from API
    fetch(args.json).then(res => res.json()).then((result) => {
      result.posts = result.posts.map((post) => ({...post, date: post.date.split('+')[0]})); // convert date for safari (only?)
      const preparedPosts = 
        // filter, sort and format post list
        preparePosts(result.posts, args.sort, args.search, args.conditions, args.allowed_terms)
          // select if slug equals URL slug
          .map((post) => ({...post,
            selected: post.slug === initalSlug
          }));
      const preparedFilters = [...(args.filters || []).map((filter) => ({...filter,
        // get each filter's options from actual post terms
        options: getFilterOptions(filter, result, preparedPosts),
      }))]
        // select an option…
        .map((filter) => ({...filter,
          // TODO: skip if no initial slug
          options: filter.include_link ? // …if it's filter is set up to change URL
            filter.options.map((option) => ({...option, selected: option.value === initalSlug})) // …and equals the URL slug
            : (
              filter.init_select ? //…or all if init_select flag
                filter.options.map((option) => ({...option, selected: true}))
              : filter.options
            )
        }));
      // update state
      setData({
        filters: preparedFilters,
        posts: preparedPosts
      });

      setTimeout(reportLoaded, 100);
    }, (error) => { console.log(error); })
  }, []);

  const reportLoaded = () => {
    window.dispatchEvent(new CustomEvent('ws_cmp_filtered_list__data_loaded'));
  }

  // a filter option has been clicked
  const handleFiltersChange = (selection) => {
    window.dispatchEvent(new CustomEvent('ws_cmp_filtered_list__filters_change'));  
    const newFilters = (filtersSelected || filters).map((filter) => ({
      ...filter,
      // apply selected status to filter
      options: selection.slug === filter.slug ?
        selection.options :
        // …or deselect if filter is being disabled by condition
        filter.options.map((option) => ({
          ...option,
          selected: !filter.condition_tax  ?
            option.selected :
            false
        }))
    }));
    if (pageNo) disablePaginatedView();
    setFiltersSelected(newFilters);
  }
  // report new settings
  React.useEffect(() => {
    window.dispatchEvent(new CustomEvent('ws_cmp_filtered_list__filters_changed', {detail: filtersSelected}));  
  }, [filtersSelected, searchTerm]);

  // change URL slug if necessary
  if (filtersSelected) {
    filtersSelected.forEach((filter) => {
      if (!filter.include_link || !filter.singular) return;
      const taxSlug = filter.options.filter((option) => option.selected).map((option) => option.value).join('');
      const newUrl = args.baseurl + (taxSlug ? taxSlug + '/' : '');
      if (newUrl === document.location.href) return;
      window.history.replaceState(null, null, newUrl);
    });
  }

  const handleSearchChange = (term) => {
    if (term.length > 2 || searchTerm.length > 2) window.dispatchEvent(new CustomEvent('ws_cmp_filtered_list__search_input'));
    setSearchTerm(term);
  }

  const handleFilterRemove = (slug, value) => {
    const newFilters = (filtersSelected || filters).map((filter) => (
      filter.slug === slug ?
        {
          ...filter,
          options: filter.options.map((option) => ({
            ...option,
            selected: option.value === value ? false : option.selected
          }))
        } :
        filter
    ));
    setFiltersSelected(newFilters);
  }

  const handleModalRequest = (slug) => {
    setData({...data,
      posts: data.posts.map(post => ({...post, selected: post.slug === slug}))
    });
  }
  const handleModalClosed = () => {
    handleModalRequest(null);
  }
  const handleModalPrevNext = (direction) => {
    const nextIndex = (data.posts.findIndex(post => post.selected) + direction + data.posts.length) % data.posts.length;
    setData({...data,
      posts: data.posts.map((post, index) => ({...post, selected: index === nextIndex}))
    });
  };

  const handleShowMore = () => {
    if (pageNo) document.location.href = args.baseurl;
    setPages(pages + 1);
  }

  const handleIntersectionChange = (intersecting) => {
    setIsIntersecting(intersecting);
  }

  const disablePaginatedView = () => {
    window.history.pushState(null, null, args.baseurl);
    setPageNo(false);    
  }

  
  const filters = (filtersSelected || data.filters || [])
    // pre-calculate filter option results
    .map((filter) => ({
      ...filter,
      options: filter.options.map((option) => ({
        ...option,
        // filter posts with this option selected and return length of result
        results: posts.filter(applyFilter, {
          filters: (filtersSelected || data.filters || []).map((assumedFilter) => ({
            ...assumedFilter, options: assumedFilter.options.map((assumedOption) => ({
              ...assumedOption,
              selected:
                (assumedFilter.slug === filter.slug && assumedOption.value === option.value) || 
                (assumedFilter.slug !== filter.slug && assumedOption.selected)
            }))
          })),
          searchTerm: searchTerm
        }).length
      }))
    }))
    // de-select options with no results
    .map((filter) => ({
      ...filter,
      options: filter.options.map((option) => ({
        ...option,
        selected: option.results && option.selected
      }))
    }));

  const isFilterSelected = searchTerm || filters.some((filter) => filter.options.some(option => option.selected));
  const filteredPosts = posts.filter(applyFilter, {
    filters: filters,
    searchTerm: searchTerm
  });
  const visiblePosts = applyPagination(filteredPosts, pages, args.show_more.items_per_page, pageNo);

  return data && (
    <div className={
      classNames("wscfl-app", {
        'wscfl-app--intersecting': isIntersecting
      })}
    >
      <Modal
        post={posts.filter((post) => post.selected)[0]}
        template={args.templates.modal}
        transitions={args.transitions}
        baseurl={args.baseurl}
        urlParams={urlParams}
        onClosed={handleModalClosed}
        onLinkClick={handleModalRequest}
        onPrevNextClick={args.modal_prev_next && handleModalPrevNext}
        noHistory={args.modal_no_history}
      />
      <FilterPanel
        onChange={handleFiltersChange}
        onSearch={handleSearchChange}
        filters={filters}
        searchTerm={searchTerm}
        filtersLabel={args.filters_label}
        searchDefs={args.search}
        searchLabel={args.search_label}
        isFilterSelected={isFilterSelected}
        baseurl={args.baseurl}
      />
      <div className="wscfl-listpanel">
        {typeof args.intersection_observer !== 'undefined' &&
          <ListIntersection
            threshold={args.intersection_observer}
            onChange={handleIntersectionChange}
          />
        }
        <FiltersList
          filters={filters}
          onRemove={handleFilterRemove}
          isFilterSelected={isFilterSelected}
        />
        <ResultsCount
          numberOfPosts={filteredPosts.length}
          labels={args.count_labels}
        />
        <List
          type={args.type}
          labels={args.labels}
          list={visiblePosts}
          templates={args.templates}
          transitions={args.transitions}
          onModalRequest={handleModalRequest}
          isDataLoaded={!!posts.length}
        />
        <ShowMore 
          filteredPosts={filteredPosts}
          visiblePosts={visiblePosts}
          pages={pages}
          perPage={args.show_more.items_per_page}
          template={args.show_more.template}
          onShowMore={handleShowMore}
          addPageParameters={args.ssr && !filtersSelected}
          pageNo={pageNo}
          baseurl={args.baseurl}
        />
      </div>
    </div>
  );

}

const ListIntersection = ({ threshold, onChange }) => {
  const intersectionRef = React.useRef(null);
  React.useEffect(() => {
    const current = intersectionRef.current;
    const observer = new IntersectionObserver((entries) => {
      const [ entry ] = entries;
      onChange(entry.isIntersecting);
    },{
      root: null,
      rootMargin: "0px",
      threshold: threshold
    });
    if (current) observer.observe(current);
    return () => { if (current) observer.unobserve(current) }
  }, [intersectionRef, threshold, onChange]);
  return (
    <div
      className="wscfl-list-intersection"
      ref={intersectionRef}
    ></div>
  );
}

export default App;