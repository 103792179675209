import React from 'react';
import classNames from "classnames";

const FiltersList = ({filters, onRemove, isFilterSelected }) => {
  
  // create array of all selected options with injected filter slug
  const options = filters
    .filter((filter) => filter.options.some((option) => option.selected)) // only filters which have a selected option
    .map((filter) => filter.options // transform to…
      .filter((option) => option.selected) // only selected options
      .map((option) => ({slug: filter.slug, ...option})) // inject filter slug
    )
    .flat() // …flat array of options

  return (
    <ul className={
      classNames("wscfl-filterslist", {
        'wscfl-filterslist--has-item': true || isFilterSelected,
      })
    }>
      {options.map((option) => (
        <li
          onClick={function(){
            onRemove(option.slug, option.value);
          }}
          key={option.slug+option.value}
        ><span className={option.slug === 'search' ? 'wscfl-filterslist__search' : null}>{option.label}</span></li>
      ))}
    </ul>
  )
}

export default FiltersList;