import dateFormat from 'dateformat';
import resolvePath from './resolvePath';

function getFilterOptions(filter, result, posts) {

  filter = {type: 'taxonomy', sort_by: 'name', ...filter};

  switch (filter.type) {

    case 'taxonomy':
      return Object.keys(result.terms[filter.slug])
        .filter(
          (termKey) => posts.some(
            (post) => post.terms[filter.slug].some(
              (term) => term.slug === result.terms[filter.slug][termKey].slug
            )
          )
        ) // only use option if there is a post with this term
        .map((slug) => result.terms[filter.slug][slug]) // get used terms from result
        .sort((a, b) => a[filter.sort_by] < b[filter.sort_by] ? -1 : 1)
        .map((option) => ({ value: option.slug, label: option.name, description: option.description })); // convert to generic dropdown options

    case 'year':
      return result.posts
        .map((post) => dateFormat(new Date(post.date), 'yyyy'))
        .filter((v, i, a) => a.indexOf(v) === i) // make unique
        .map((date) => ({
          label: date,
          value: date
        }));

    case 'string':
      return result.posts
        .map((post) => resolvePath(post, filter.path))
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort((a, b) => a < b ? -1 : 1)
        .map((option) => ({
          label: option,
          value: option
        }));

    default:
      throw new Error('getFilterOptions: Unknown filter type');

  }

}

export default getFilterOptions;