import resolvePath from './resolvePath';

const templateApplyTags = (data, template) => {

  // [if condition]content[/if]
  const matches = [...template.matchAll(/\[if (.+?)\][\s\S]+?\[\/if\]/g)];
  matches.forEach((match) => {
    template = template.replace(
      match[0],
      resolvePath(data, match[1], '') === '' ?
        '' :
        match[0].replace(/\[if .+?\]([\s\S]+?)\[\/if\]/g, '$1')
    );
  });

  // [subif condition]content[/subif]
  const subMatches = [...template.matchAll(/\[subif (.+?)\][\s\S]+?\[\/subif\]/g)];
  subMatches.forEach((match) => {
    template = template.replace(
      match[0],
      resolvePath(data, match[1], '') === '' ?
        '' :
        match[0].replace(/\[subif .+?\]([\s\S]+?)\[\/subif\]/g, '$1')
    );
  });

  return template;

}

export default templateApplyTags;