import React from 'react';
import parse from 'html-react-parser';

const ShowMore = ({ filteredPosts, visiblePosts, pages, perPage, template, onShowMore, addPageParameters, pageNo, baseurl }) => {

  const filtered = filteredPosts.filter((post) => !post.isDivider).length;
  const visible  = visiblePosts.filter((post) => !post.isDivider).length;
  if (
    !filteredPosts || !pages || !perPage ||
    visible >= filtered ||
    pages * perPage >= filteredPosts.filter((post) => !post.isDivider).length
  ) return null;
  
  if (typeof template === 'string') return (
    <div className="wscfl-more" onClick={onShowMore}>{parse(template)}</div>
  )

  const Tag = `${template.tag}`;
  return (
    <div className="wscfl-more" onClick={onShowMore}>
      <Tag
        {...template.attributes}
        onClick={(e) => {e.preventDefault()}}
      >
        {parse(pageNo && template.content_all ? template.content_all : template.content)}
      </Tag>
    </div>
  )

  // if using ssr and pagination, add a page parameter to make search engines crawl paginated pages
  

}

export default ShowMore;