function applyPagination(posts, pages, perPage, pageNo) {

  if (
    !perPage ||
    posts.some((post) => post.isDivider) // pagination currently only supported when not grouping
  ) return posts;

  if (pageNo) {
    return posts.filter((post, i) =>
      i >= (pageNo - 1) * perPage &&
      i < (pageNo - 1) * perPage + perPage); 
  }

  return posts.filter((post, i) => i < pages * perPage);

}

export default applyPagination;