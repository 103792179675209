import React from 'react';
import Dropdown from './Dropdown';
import classNames from "classnames";

function FilterPanel({ onChange, onSearch, filters, searchTerm, filtersLabel, searchDefs, searchLabel, isFilterSelected, baseurl }) {

  const [activeFilter, setActiveFilter] = React.useState(null);

  function handleToggle(idx) {
    setActiveFilter(idx === activeFilter ? null : idx);
  }

  return (
    <div className={
      classNames("wscfl-filterpanel", {
        'wscfl-filterpanel--filtered': isFilterSelected,
      })
    }>

      <Search
        search={searchTerm}
        onSearch={onSearch}
        searchDefs={searchDefs}
        searchLabel={searchLabel}
      />

      <FiltersLabel label={filtersLabel} />
      
      <div className="wscfl-items">
        {filters
          // check for conditions from args.filters[…].condition_tax
          .filter((filter) => (
            !filter.condition_tax ||
            filters.some((conditionFilter) => 
              conditionFilter.slug === filter.condition_tax &&
              conditionFilter.options.some((option) => option.selected && filter.condition_slugs.includes(option.value))
            )
          ))
          .map((filter, idx) => (
            <Filter
             key={'filter-'+idx}
             idx={idx}
             isActive={activeFilter === idx}
             onSelect={onChange}
             onToggle={handleToggle}
             baseurl={baseurl}
             {...filter}
            />
          ))
        }
       </div>
    </div>
  )
}

function FiltersLabel({ label }) {
  if (!label) return null;
  return(
    <div className="wscfl-filterpanel__label">{label}</div>
  );
}

function Filter({ 
  slug,
  name,
  type,
  options,
  singular,
  sort_by,
  onSelect,
  onToggle,
  idx,
  isActive,
  clear_label,
  class_name,
  include_link,
  baseurl,
  show_description,
  show_results
}) {

  function handleSelect(option) {
    onSelect({
      type: type,
      slug: slug,
      options: option,
    });
  }

  function handleToggle() {
    onToggle(idx);
  }

  return(
    <Dropdown
      title={name}
      options={options}
      onChange={handleSelect}
      onToggle={handleToggle}
      isActive={isActive}
      singular={singular}
      clearLabel={clear_label}
      className={class_name}
      includeLink={include_link}
      baseurl={baseurl}
      showDescription={show_description}
      showResults={show_results}
    />
  );
}

function Search({ search, onSearch, searchDefs, searchLabel }) {
  if (typeof searchDefs !== 'object' || !searchDefs.length) return null;
  const handleSearch = (event) => {
    onSearch(event.target.value);
  }
  return (
    <div className="wscfl-search">
      <label htmlFor="search">{searchLabel}</label>
      <input
        id="search"
        type="text"
        value={search}
        onChange={handleSearch}
      />
    </div>
  );
}

export default FilterPanel;