// import dateFormat from 'dateformat';
import resolvePath from './resolvePath';

function applyFilter(post) {
  this.filters = this.filters.map((filter) => ({...filter, type: filter.type || 'taxonomy'})); // default type is taxonomy
  return this.filters.every((filter) => {
    switch (filter.type) {
      case 'taxonomy':
        // make array of selected terms
        const selectedTerms = filter.options.filter((option) => option.selected).map((option) => option.value);
        // match array with post terms
        return !selectedTerms.length || post.terms[filter.slug]?.some((term) => selectedTerms.includes(term.slug));
      case 'year' :
        // if (!this[slug].selected.length) break;
        // isIn = isIn && this[slug].selected.some(
        //   item => dateFormat(new Date(post.date), 'yyyy') === item.value
        // );
        return true;
      case 'string' :
        // make array of selected strings
        const selectedStrings = filter.options.filter((option) => option.selected).map((option) => option.value);
        // check if post has this string in the field specified in filter definition
        return !selectedStrings.length || selectedStrings.includes(resolvePath(post, filter.path));
      default:
        return true;
    }
  }) && (
    this.searchTerm.length < 3 ||
    post.search.some((searchItem) => searchItem.value.length && searchItem.value.toLowerCase().includes(this.searchTerm.toLowerCase()))
  );
}

export default applyFilter;