import React from 'react';
import classNames from "classnames";

function Dropdown({ title, options, onChange, onToggle, isActive, singular, clearLabel, className, includeLink, baseurl, showDescription, showResults }) {

  options = (
    clearLabel ? [{value: '_deselect', label: clearLabel}] : []).concat( // add deselect option if required
      // add selected flag to options
      options.map((option) => ({
        ...option,
        selected: option.selected || false
      }))
    );

  const [activated, setActivated] = React.useState(false);

  function handleSelect(value) {

    // update activated status if in singular mode and the selected option has been clicked again (enables dropdown mode)
    if (singular && !activated && (
      (value === '_deselect' && !options.some(option => option.selected)) || // clicked deselect again
      (options.some(option => option.selected && option.value === value)) // clicked selected option again
    )) {
      // do nothing, only set to active
      setActivated(true);
      return;
    }
    setActivated(false);

    // …or update selection
    onChange(options
      .filter((option) => option.value !== '_deselect')
      .map((option) => ({
        ...option,
        // set selected flag
        selected: 
          value !== '_deselect' && // deselect option has not been clicked
          // is this the option which has been clicked?
          option.value === value ? 
            singular || !option.selected : // yes, toggle flag
            singular ? false : option.selected // no, switch off if singular selection required otherwise leave alone
      }))
    );

  }

  function handleClearClick() {
    onChange(options.map((option) => ({selected: false, ...option})));
  }

  return (
    <div className={
      classNames("wscdd" + (className ? (' ' + className) : ''), {
        'wscdd--is-active': isActive,
        'wscdd--is-activated': activated,
        'wscdd--has-value': options.some(option => option.selected),
      })
    }>
      <div className="wscdd__panel">
        <div className="wscdd__title">{
          title.split('|').length > 1 ?
            title.split('|').map((titleSegment) => (<span>{titleSegment}</span>)) : title
          }
        </div>
        <div className="wscdd__control">
          <i className="wscdd__toggle" onClick={onToggle}></i>
          <i className="wscdd__clear" onClick={handleClearClick}></i>
        </div>
      </div>
      <div className="wscdd__options">
        {options.map(option => {
          return (
            option.value &&
            <Option
              key={option.value}
              value={option.value}
              label={option.label}
              description={showDescription ? option.description : ''}
              results={option.results}
              showResults={showResults}
              onSelect={handleSelect}
              includeLink={includeLink}
              baseurl={baseurl}
              isSelected={option.selected || (
                // select the deselect option if nothing is selected
                option.value === '_deselect' &&
                !options.some((selectedOption) => selectedOption.selected)
              )}
            />
          );
        })}
      </div>
    </div>
  );
}

function Option({ value, label, onSelect, isSelected, includeLink, baseurl, description, results, showResults }) {
  const handleClick = (e) => {
    e.preventDefault();
    onSelect(value);
  };
  if (includeLink && value !== '_deselect') {
    return (
      <a
        href={baseurl + value + '/'}
        className={classNames("wscdd__option", {'wscdd__option--is-selected': isSelected})}
        onClick={handleClick}
      ><span>{label}</span></a>
    );
  } else {
    const descriptionArray = description.split('|');
    return (
      <div
        className={classNames("wscdd__option", {
          'wscdd__option--is-selected': isSelected,
          'wscdd__option--no-results': !results
        })}
        onClick={handleClick}
      >
        <span className="wscdd__option-label">{label}</span>
        {showResults &&
          <span className="wscdd__option-results">{results}</span>
        }
        {description &&
          <div className="wscdd__option-description">
            <div className="wscdd__option-description-text">{descriptionArray[0]}</div>
            {descriptionArray.length > 1 ? 
              <span className="wscdd__option-description-button">{descriptionArray[1]}</span>
            : null}
          </div>
        }
      </div>
    );
  }
}

export default Dropdown;